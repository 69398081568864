<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="tabNav">
      <div v-for="(item, index) in recruit" :key="index" class="navItem" @click="navChange(index)">
        <div :class="navIndex == index ? 'name navActive' : 'name'"> {{ item.name }} </div>
        <div class="xian" v-if="index < recruit.length - 1"></div>
      </div>
    </div>
    <div class="outer collapseEmploy">
      <el-collapse accordion v-model="activeName" @change="handleChange">
        <el-collapse-item class="employ" v-for="(item, index) in recruitList" :key="index" :name="index">
          <template slot="title">
            <div class="employOne" v-if="item.see == 0">
              <div>
                <div class="tipOne"> {{ $t("talentRecruitment.TR_tipOne") }} </div>
                <div class="title">{{ item.title }}</div>
              </div>
              <div class="tipTwo">
                <div>{{ $t("talentRecruitment.TR_tipTwo") }}</div>
                <img src="@/assets/img/see.png" alt="" />
              </div>
            </div>
            <div class="employOne" v-if="item.see == 1">
              <div>
                <div class="tipOne" v-html="$t('talentRecruitment.TR_tipOne_br')"></div>
              </div>
              <div class="tipTwo tipThree">
                <div>{{ $t("talentRecruitment.TR_tipThree") }}</div>
                <img src="@/assets/img/putaway.png" alt="" />
              </div>
            </div>
          </template>
          <div class="employCont">
            <div class="contOne">
              <div class="title">{{ item.title }}</div>
              <div class="rens"> {{ item.rens }} {{ $t("talentRecruitment.TR_tipFour") }} </div>
              <div class="xuel">{{ item.xuel }}</div>
              <!-- <div class="upload" @click="upResume(item)">上传简历</div> -->
              <el-upload class="upload-demo" ref="upload" :action="BASE_URL + '/Common/qiniu'" name="img"
                :data="uploadData" :on-success="upResumeSuccess" :on-error="upResumeError" :show-file-list="false">
                <el-button slot="trigger" class="upload" size="small" type="primary"
                  @click="upResume(item)">上传简历</el-button>
                <!-- <el-button
                  style="margin-left: 10px"
                  size="small"
                  type="success"
                  @click="submitUpload"
                  >上传到服务器</el-button
                > -->
                <!-- <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过500kb
                </div> -->
              </el-upload>
            </div>
            <div class="contTwo">
              <div class="tip">
                <span class="dian"></span>
                <div>培养方向 :</div>
              </div>
              <div class="fangx">{{ item.fangx }}</div>
            </div>
            <div class="contTwo">
              <div class="tip">
                <span class="dian"></span>
                <div>专业 :</div>
              </div>
              <div class="fangx">{{ item.zhuany }}</div>
            </div>
            <div class="contThree">
              <div class="tipThree">岗位职责：</div>
              <div class="zhizjson" v-html="item.zhizjson"></div>
              <!-- <div
                v-for="(item, index) in item.zhizjson"
                :key="index"
                class="zhizjson"
              >
                {{ index + 1 }}、{{ item.name }}
              </div> -->
            </div>
            <div class="contThree">
              <div class="tipThree">任职要求：</div>
              <div class="zhizjson" v-html="item.yaoqjson"></div>
              <!-- <div
                v-for="(item, index) in item.yaoqjson"
                :key="index"
                class="zhizjson"
              >
                {{ index + 1 }}、{{ item.name }}
              </div> -->
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：人才招聘-人才招聘
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-15 14:00
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "人才招聘",
          title_en: "Talent recruitment",
          path: "recruitment",
        },
        {
          title_zh: "人才招聘",
          title_en: "Talent recruitment",
          path: "",
        },
      ],
      navIndex: 0,
      recruit: "",
      recruitList: [],
      activeName: "",
      uploadData: {
        token: localStorage.getItem("token"),
      },
      recruit_id: "",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.geRecruitClass();
  },
  methods: {
    /**
     * 头部切换
     * 刘嘉鑫
     * 2022-8-15
     */
    navChange(index) {
      this.navIndex = index;
      this.getRecruitList();
    },
    /**
     * 分类
     * 刘嘉鑫
     * 2022-8-19
     */
    geRecruitClass() {
      this.$request({
        url: "/Index/recruitType",
        data: {},
      }).then((res) => {
        console.log("招聘分类", res);
        this.recruit = res.recruit_type;
        this.getRecruitList();
      });
    },

    /**
     * 列表
     * 刘嘉鑫
     * 2022-8-19
     */
    getRecruitList() {
      this.$request({
        url: "/Index/recruitList",
        data: {
          type_id: this.recruit[this.navIndex].id,
        },
      }).then((res) => {
        console.log("招聘列表", res);
        res.recruit_list.map((item, index) => {
          return (item.see = 0);
        });
        if (this.id != "") {
          for (let i in res.recruit_list) {
            if (res.recruit_list[i].id == this.id) {
              res.recruit_list[i].see = 1;
              this.activeName = Number(i);
            }
          }
        }
        this.recruitList = res.recruit_list;
      });
    },

    /**
     * 查看
     * 刘嘉鑫
     * 2022-8-19
     */
    handleChange(val) {
      console.log(val);

      if (val !== "") {
        for (let i in this.recruitList) {
          if (i == val) {
            this.recruitList[i].see = 1;
          } else {
            this.recruitList[i].see = 0;
          }
        }
      } else {
        setTimeout(() => {
          this.recruitList.map((item) => {
            return (item.see = 0);
          });
        }, 200);
      }
    },

    /**
     * 上传简历
     * 刘嘉鑫
     * 2022-9-5
     */
    upResume(item) {
      console.log(item);
      this.recruit_id = item.id;
    },
    submitUpload() {
      console.log("上传");
      // this.$refs.upload.submit();
    },
    upResumeSuccess(res, item) {
      console.log(res);
      console.log(item);
      this.$request({
        url: "/User/submitRecruit",
        data: {
          img: res.data.fullurl,
          token: localStorage.getItem("token"),
          recruit_id: this.recruit_id,
        },
      })
        .then((res2) => {
          console.log("上传简历", res2);
          this.$message({
            message: "简历上传成功",
            type: "success",
          });
          this.$message("");
          this.recruit_id = "";
        })
        .catch((error) => {
          this.$message.error("请上传word或PDF格式的简历");
          this.recruit_id = "";
        });
    },
    upResumeError(res, file) {
      console.log(res);
    },
  },
};
</script>
<style>
.collapseEmploy .el-collapse {
  border: none !important;
}

.employ .el-collapse-item__header {
  height: 80px !important;
  line-height: inherit !important;
  border: none !important;
}

.employ .el-icon-arrow-right {
  display: none;
}

.employ .el-collapse-item__wrap {
  border: none !important;
}

.upload-demo {
  margin-left: auto;
}
</style>
<style lang='scss' scoped>
.tabNav {
  padding: 0 260px;
  background: #ffffff;
  display: flex;
  align-items: center;

  .navItem {
    padding: 25px 0 0;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
  }

  .name {
    padding: 0 18px;
    border-bottom: 4px solid transparent;
    font-size: 18px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #444444;
    padding-bottom: 21px;
    margin-left: 42px;
  }

  .xian {
    width: 2px;
    height: 15px;
    background: #cccccc;
    margin: 5px 0 0 42px;
  }

  .navActive {
    color: #1a2a60;
    border-bottom: 4px solid #1a2a60;
  }
}

.outer {
  background: #f6f6f6;
  padding: 80px 310px 40px;
}

.employ {
  background: #ffffff;
  margin-bottom: 40px;
  padding: 30px 60px;

  .employOne {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 40px;
    width: 100%;

    .tipOne {
      font-size: 24px;
      font-family: FZFengYaSongS-GB;
      font-weight: 400;
      color: #c5c5c5;
    }

    .title {
      font-size: 22px;
      font-family: FZFengYaSongS-GB;
      font-weight: bold;
      color: #1a2a60;
      margin-top: 19px;
    }

    .tipTwo {
      display: flex;
      align-items: center;

      >div {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #aaaaaa;
      }

      >img {
        width: 16px;
        height: 16px;
        margin-left: 12px;
      }
    }

    .tipThree>div {
      color: #1a2a60 !important;
    }
  }

  .employCont {
    .contOne {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      width: 100%;

      .title {
        font-size: 22px;
        font-family: FZFengYaSongS-GB;
        font-weight: bold;
        color: #1a2a60;
        margin-right: 30px;
      }

      .rens {
        background: #1a2a60;
        padding: 0px 8px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        margin-right: 30px;
      }

      .upload {
        font-size: 18px !important;
        font-weight: bold !important;
        color: #ffffff !important;
        margin-left: auto !important;
        background: #1a2a60 !important;
        border-radius: 6px !important;
        cursor: pointer !important;
        padding: 8px 10px !important;
        border: none !important;
      }

      .xuel {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1a2a60;
        // margin-left: auto;
      }
    }

    .contTwo {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .tip {
        display: flex;
        align-items: center;
        padding-left: 2px;

        .dian {
          width: 9px;
          height: 9px;
          background-color: #1a2a60;
          transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          /* Internet Explorer */
          -moz-transform: rotate(45deg);
          /* Firefox */
          -webkit-transform: rotate(45deg);
          /* Safari 和 Chrome */
          -o-transform: rotate(45deg);
          /* Opera */
          margin-right: 14px;
        }

        >div {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #1a2a60;
        }
      }

      .fangx {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }
    }

    .contThree {
      margin: 40px 0 20px;

      .tipThree {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        margin-bottom: 30px;
      }

      .zhizjson {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-bottom: 10px;
      }
    }
  }
}
</style>